import React from "react";
import {
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
} from "@mui/material";

let informations = [
    {
        title: "First Name",
        value: localStorage.getItem("fname"),
    },
    {
        title: "Last Name",
        value: localStorage.getItem("lname"),
    },
    {
        title: "Email",
        value: localStorage.getItem("email"),
    },
    {
        title: "Member",
        value: "Regular",
    },
];

export default function Personal() {
    return (
        <div className=" container row me-auto ms-auto ">
            {informations.map((item) => (
                <Card
                    sx={{
                        minWidth: 100,
                        margin: "1.25rem",
                        width: "calc(100% - 2.5rem)",
                        maxWidth: "calc(100% - 2.5rem)",
                        "@media (min-width: 768px)": {
                            maxWidth: "calc(50% - 2.5rem)",
                        },
                    }}
                    className="cardd"
                    key={item.title}
                >
                    <CardContent sx={{ minWidth: 100 }}>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            {item.title}
                        </Typography>
                        <Typography variant="h5" component="div">
                            {item.value}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
}
