import errorL from "../pics/403.svg";
export default function Errorpro(props) {


    return (
        <div className="container ">
            <div className="row error-page align-items-center">
                <div className="col-6 justify-content-center d-flex flex-column ">
                    <h3 className="">No result for "{props.search}" </h3>
                    <h5 className="">{props.cause}Product Not Found</h5>
                </div>
                <div className="col-6">
                    <img src={errorL} className="img-fluid" alt="error" />
                </div>
            </div>
        </div>
    );
}
