import React from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Footer() {
    const handleSubscribe = () => {
        Swal.fire({
            title: "Subscribed!",
            text: "You have successfully subscribed to our newsletter!",
            icon: "success",
            confirmButtonText: "Cool",
        });
    };

    let topView = () => {
        window.scrollTo(0, 0);
    };

    let id = localStorage.getItem("id");
    return (
        <div className=" ">
            <footer className="bg-dark text-center text-white">
                <div className="container p-4">
                    <section className="mb-4">
                        <a
                            className="btn btn-outline-light btn-floating m-1"
                            href="https://www.facebook.com/profile.php?id=100090883335804"
                            role="button"
                        >
                            <i className="fab fa-facebook-f"></i>
                        </a>

                        <a
                            className="btn btn-outline-light btn-floating m-1"
                            href="#!"
                            role="button"
                        >
                            <i className="fab fa-google"></i>
                        </a>

                        <a
                            className="btn btn-outline-light btn-floating m-1"
                            href="https://instagram.com/mouad_kh_3?igshid=ZDdkNTZiNTM="
                            role="button"
                        >
                            <i className="fab fa-instagram"></i>
                        </a>

                        <a
                            className="btn btn-outline-light btn-floating m-1"
                            href="https://www.linkedin.com/in/mouad-kahla"
                            role="button"
                        >
                            <i className="fab fa-linkedin-in"></i>
                        </a>

                        <a
                            className="btn btn-outline-light btn-floating m-1"
                            href="https://github.com/23Mouad"
                            role="button"
                        >
                            <i className="fab fa-github"></i>
                        </a>
                    </section>

                    <section className="">
                        <form>
                            <div className="row d-flex justify-content-center">
                                <div className="col-auto">
                                    <p className="pt-2">
                                        <strong>
                                            Sign up for our newsletter
                                        </strong>
                                    </p>
                                </div>

                                <div className="col-md-5 col-12">
                                    <div className="form-outline form-white mb-4">
                                        <input
                                            type="email"
                                            id="form5Example2"
                                            className="form-control"
                                        />
                                        <label
                                            className="form-label"
                                            htmlFor="form5Example2"
                                        >
                                            Email address
                                        </label>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <button
                                        type="submit"
                                        className="btn btn-outline-light mb-4"
                                        onClick={handleSubscribe}
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </form>
                    </section>

                    <section className="mb-4">
                        <p style={{ fontSize: "14px" }}>
                            At SmartOn, we pride ourselves on providing a unique
                            and special shopping experience for those seeking
                            the latest and greatest in smart devices
                        </p>
                    </section>

                    <section className="">
                        <div className="row">
                            <div className="col-md-4 mb-4 mb-md-0 footer-links">
                                <h6 className="text-uppercase">
                                    Get to Know Us
                                </h6>

                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/about"
                                            className="text-white"
                                        >
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/service"
                                            className="text-white"
                                        >
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/service"
                                            className="text-white"
                                        >
                                            Careers
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 mb-4 mb-md-0 footer-links ">
                                <h6 className="text-uppercase">Products</h6>

                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/search/smartphone"
                                            className="text-white"
                                        >
                                            Smartphones
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/search/smartwatch"
                                            className="text-white"
                                        >
                                            Smartwatches
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/search/keyboard"
                                            className="text-white"
                                        >
                                            Keyboards
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/search/mouse"
                                            className="text-white"
                                        >
                                            Mouses
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-4 mb-4 mb-md-0 footer-links">
                                <h6 className="text-uppercase">
                                    Let Us Help You
                                </h6>

                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to="/login"
                                            className="text-white"
                                        >
                                            Your Account
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to={`/cart/${id} `}
                                            className="text-white"
                                        >
                                            Your Cart
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={topView}
                                            to={`/wishlist/${id} `}
                                            className="text-white"
                                        >
                                            Your Wishlist
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>

                <div
                    className="text-center p-3"
                    style={{
                        background: "rgba(0, 0, 0, 0.2)",
                        fontSize: "12px",
                    }}
                >
                    ©2023 SmartOn by Mouad kahla. All rights reserved.
                </div>
            </footer>
        </div>
    );
}
