import React from "react";
import { ReactComponent as Search } from "../icons/search.svg";
import { ReactComponent as Cart } from "../icons/cart.svg";
import { ReactComponent as Dark } from "../icons/dark.svg";
import { ReactComponent as Light } from "../icons/light.svg";
import { ReactComponent as Account } from "../icons/account.svg";
import { ReactComponent as Hambar } from "../icons/hambar.svg";
import logonav from "../pics/logo-nav.png";
import { ReactComponent as Wish } from "../icons/wish.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Nav(props) {
    let [darkmode, setDarkmode] = React.useState(() => {
        const savedMode = localStorage.getItem("darkMode");
        return savedMode ? JSON.parse(savedMode) : false;
    });
    const Navigate = useNavigate();
    function darkmodeHandler() {
        setDarkmode(!darkmode);
        props.updateDarkMode(!darkmode);
    }

    let admin = "admin@mail.com";
    let verify = localStorage.getItem("email") === admin ? true : false;
    let token = localStorage.getItem("token");

    //search bar
    const [query, setQuery] = React.useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        Navigate(`/search/${query}`);
    };
    const userid = localStorage.getItem("id");

    return (
        <>
            <nav className="navi navbar navbar-expand-lg ">
                <Link className="navbar-brand mt-auto " to="/">
                    <img className="" src={logonav} alt="Logo "></img>
                </Link>

                {/* Nav links  */}

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav align-items-center d-flex">
                        <li className="nav-item active d-flex align-items-center nav-item dropdown">
                            <a
                                className="navi-linko dropdown-toggle"
                                id="navbarDropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                href="#"
                            >
                                Categories{" "}
                            </a>

                            <div
                                className="dropdown-menu dropdown-primary dropee"
                                aria-labelledby="navbarDropdownMenuLink"
                            >
                                <Link
                                    className="dropdown-item"
                                    to="/category/Keyboard"
                                >
                                    Keyborad
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/category/Smartwatch"
                                >
                                    Smartwatch
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/category/Headphone"
                                >
                                    Headphone
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/category/Mouse"
                                >
                                    Mouse
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/category/Smartphone"
                                >
                                    Smartphone
                                </Link>
                            </div>
                        </li>

                        <li className="nav-item ">
                            <Link className=" navi-linko " to={"/about"}>
                                About
                            </Link>
                        </li>
                        <li className="nav-item ms-2">
                            <Link className="navi-linko" to={"/service"}>
                                Contact
                            </Link>
                        </li>
                        {verify ? (
                            <Link
                                className="navi-linko link-warning"
                                to="/upload"
                            >
                                Upload
                            </Link>
                        ) : (
                            <></>
                        )}
                    </ul>
                </div>

                {/* Search bar  */}

                <form
                    className=" Search-nav col-12  col-md-5 justify-content-center "
                    onSubmit={handleSubmit}
                >
                    <input
                        type="text"
                        name="search"
                        placeholder="Search for products ..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        autoComplete="off"
                    />
                    <button type="submit">
                        {" "}
                        <Search />{" "}
                    </button>
                </form>

                {/* adding items  */}
                <div className="navi--add ">
                    <ul className=" list-unstyled  align-items-center d-flex ms-xl-5 mb-0 ms-0">
                        <li className="nav-item ms-2">
                            <Link
                                className="nav-link navi-linko"
                                to="/login"
                                title="Account"
                            >
                                <Account />
                            </Link>
                        </li>
                        {token ? (
                            <>
                                <li className="nav-item ms-2">
                                    <Link
                                        className="nav-link navi-linko cart "
                                        to={`/cart/${userid}`}
                                        title="Cart"
                                    >
                                        <Cart />
                                    </Link>
                                </li>
                                <li className="nav-item ms-2">
                                    <Link
                                        className="nav-link navi-linko "
                                        to={`/wishlist/${userid}`}
                                        title="Wish List"
                                    >
                                        <Wish />
                                    </Link>
                                </li>{" "}
                            </>
                        ) : (
                            <></>
                        )}

                        <li className="nav-item ms-2">
                            <button
                                className="nav-link navi-linko darkmode"
                                href="#"
                                onClick={darkmodeHandler}
                            >
                                {darkmode ? <Light /> : <Dark />}
                            </button>
                        </li>
                    </ul>
                </div>

                {/* Button toggle  */}
                <button
                    className="  toggol-nav d-lg-none  "
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <Hambar />
                </button>
            </nav>
        </>
    );
}
