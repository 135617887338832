import "./App.css";
import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Nav from "./component/navbar";
import Home from "./mainpage/home";
import Product from "./product/product";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./component/footer";

import "./verse.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Personal from "./other/personal";
import { lazy, Suspense } from "react";

const About = lazy(() => import("./other/About"));
const Login = lazy(() => import("./other/login"));
const Signup = lazy(() => import("./other/signup"));
const Account = lazy(() => import("./other/account"));
const Upload = lazy(() => import("./other/upload"));
const Probycat = lazy(() => import("./product/probycat"));
const Searchcomp = lazy(() => import("./mainpage/search"));
const Errorpage = lazy(() => import("./component/errorpage"));
const Wishlist = lazy(() => import("./other/wishlist"));
const Cart = lazy(() => import("./other/cart"));

export default function App() {
   let [darkmode, setDarkmode] = React.useState(() => {
      const savedMode = localStorage.getItem("darkMode");
      return savedMode ? JSON.parse(savedMode) : false;
   });
   React.useEffect(() => {
      localStorage.setItem("darkMode", JSON.stringify(darkmode));
   }, [darkmode]);

   const [Products, setProducts] = React.useState([]);

   React.useEffect(() => {
      fetch("https://smart-on-api.onrender.com/products")
         .then((res) => res.json())
         .then((data) => {
            setProducts(data.products);
         });
   }, []);

   const darkTheme = createTheme({
      palette: {
         mode: darkmode ? "dark" : "light",
      },
   });

   const [wishlist, setWishlist] = React.useState([]);
   const [cart, setCart] = React.useState([]);

   React.useEffect(() => {
      if (localStorage.getItem("id") === null) {
         return;
      }
      fetch(
         "https://smart-on-api.onrender.com/wishlist/" +
            localStorage.getItem("id")
      )
         .then((res) => res.json())
         .then((data) => {
            setWishlist(data.wishlist);
         });
   }, [localStorage.getItem("wishlist")]);

   React.useEffect(() => {
      if (localStorage.getItem("id") === null) {
         return;
      }
      fetch(
         "https://smart-on-api.onrender.com/cart/" + localStorage.getItem("id")
      )
         .then((res) => res.json())
         .then((data) => {
            setCart(data.cart);
         });
   }, [localStorage.getItem("cart")]);

   //for hide element for specific route
   let hideloc = useLocation();

   let hide =
      hideloc.pathname === "/login" ||
      hideloc.pathname === "/signup" ||
      hideloc.pathname === "/upload";

   function updateDarkMode(newValue) {
      setDarkmode(newValue);
   }

   //Loading screen
   const [loading, setLoading] = React.useState(true);

   React.useEffect(() => {
      const timer = setTimeout(() => {
         setLoading(false);
      }, 2000); // Replace this with your own loading logic
      return () => clearTimeout(timer);
   }, []);
   return (
      <>
         {!loading ? (
            <ThemeProvider theme={darkTheme}>
               <CssBaseline />
               <main className={`app ${darkmode ? "darkmode" : ""}`}>
                  <Suspense
                     fallback={
                        <div className="container-loader container">
                           <div className="loader"></div>
                           <div className="loader"></div>
                           <div className="loader"></div>
                        </div>
                     }
                  >
                     {!hide && <Nav updateDarkMode={updateDarkMode} />}
                     <Routes>
                        <Route
                           path="/"
                           element={
                              <Home
                                 updateDarkMode={darkmode}
                                 products={Products}
                              />
                           }
                        />
                        <Route
                           path="/product/:id"
                           element={
                              <Product
                                 products={Products}
                                 updateDarkMode={darkmode}
                              />
                           }
                        />
                        <Route
                           path="/about"
                           element={<About updateDarkMode={darkmode} />}
                        />
                        <Route
                           path="/service"
                           element={<About updateDarkMode={darkmode} />}
                        />
                        <Route
                           path="/login"
                           element={<Login updateDarkMode={darkmode} />}
                        />
                        <Route
                           path="/signup"
                           element={<Signup updateDarkMode={darkmode} />}
                        />
                        <Route
                           path="/account"
                           element={<Account updateDarkMode={darkmode} />}
                        />
                        <Route
                           path="/upload"
                           element={<Upload updateDarkMode={darkmode} />}
                        />
                        <Route
                           path="/category/:cat"
                           element={
                              <Probycat
                                 products={Products}
                                 updateDarkMode={darkmode}
                              />
                           }
                        />
                        <Route
                           path="/search/:search"
                           element={
                              <Searchcomp
                                 products={Products}
                                 updateDarkMode={darkmode}
                              />
                           }
                        />
                        <Route
                           path="/wishlist/:id"
                           element={
                              <Wishlist
                                 wishlist={wishlist}
                                 products={Products}
                                 updateDarkMode={darkmode}
                              />
                           }
                        />
                        <Route
                           path="/cart/:id"
                           element={
                              <Cart
                                 cart={cart}
                                 products={Products}
                                 updateDarkMode={darkmode}
                              />
                           }
                        />
                        <Route
                           path="/account/personal"
                           element={<Personal />}
                        />

                        <Route
                           path="*"
                           element={<Errorpage updateDarkMode={darkmode} />}
                        />
                     </Routes>
                     {!hide && <Footer />}
                  </Suspense>
               </main>{" "}
            </ThemeProvider>
         ) : (
            <div className="container-loader container">
               <div className="loader"></div>
               <div className="loader"></div>
               <div className="loader"></div>
            </div>
         )}
      </>
   );
}
