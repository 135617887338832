import React from "react";
import heroimg from "../pics/heroimg.png";

export default function HeroPage(props) {
    const name = localStorage.getItem("name");
    return (
        <div className=" container mt-5">
            <div className="row row-cols-1 row-cols-md-2 heropage   ">
                <div
                    className={
                        props.updateDarkMode
                            ? "col mb-4 firstpart darkmode"
                            : "col mb-4 firstpart "
                    }
                >
                    <p>
                        All you <span>need</span> <br /> in single{" "}
                        <span>direction</span>{" "}
                    </p>
                    <h5>
                        {`Find the Best Selection of Smart Devices and
                        Accessories, All in One Place. `}
                    </h5>
                </div>

                <div className="col mb-4 secondpart ">
                    <img src={heroimg} alt="heroimg " className=" img-fluid" />
                </div>
            </div>
        </div>
    );
}
