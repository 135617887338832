import React from "react";
import Errorpro from "../component/errorpro";
import "../App.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export default function Product(props) {
   const { id } = useParams();
   // for the two buttons in the product page

   const cartString = localStorage.getItem("cart") || "[]"; // default value is an empty array
   const msfavv = cartString.includes(id);
   const [isIncart, setIsIncart] = React.useState(msfavv);

   const navigate = useNavigate();
   const product = props.products.find((item) => {
      return item._id === id;
   });
   if (!product) {
      return (
         <Errorpro
            updateDarkMode={props.updateDarkMode}
            search={product.title}
         />
      );
   }

   const ReadMore = ({ children }) => {
      const text = children;
      const [isReadMore, setIsReadMore] = React.useState(true);
      const toggleReadMore = () => {
         setIsReadMore(!isReadMore);
      };
      return (
         <p className="text">
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
               {isReadMore ? "...read more" : " show less"}
            </span>
         </p>
      );
   };

   let nbrstars = product.stars;
   let stars = [];
   for (let i = 0; i < nbrstars; i++) {
      stars.push(<i className="fa-solid fa-star star-ico " key={i}></i>);
   }
   let image = `..${product.image} `;
   const token = localStorage.getItem("token");

   function handleAddTocart() {
      if (!token) {
         Swal.fire({
            title: "You are not logged in !",
            text: "for add to wishlist you need to login first",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Log in",
         }).then((result) => {
            if (result.isConfirmed) {
               localStorage.clear();
               navigate("/login");
            }
         });
         return;
      }

      fetch("https://smart-on-api.onrender.com/cart", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            productId: id,
            userId: localStorage.getItem("id"),
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            setIsIncart(true);
            localStorage.setItem("cart", data.cart);
         })
         .catch((err) => console.error(err));
   }

   function handleRemoveFromcart() {
      const token = localStorage.getItem("token");

      if (!token) {
         // handle the case where the user is not logged in
         return;
      }

      fetch(`https://smart-on-api.onrender.com/cart/${id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            productId: product.id,
            userId: localStorage.getItem("id"),
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            setIsIncart(false);
            localStorage.setItem("cart", data.cart);
         })

         .catch((err) => console.error(err));
   }
   function buymnow() {
      if (!token) {
         Swal.fire({
            title: "You are not logged in !",
            text: "for add to wishlist you need to login first",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Log in",
         }).then((result) => {
            if (result.isConfirmed) {
               localStorage.clear();
               navigate("/login");
            }
         });
         return;
      } else {
         Swal.fire({
            title: `You will buy ${product.title} ?`,
            text: `for ${product.price} $`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
         }).then((result) => {
            if (result.isConfirmed) {
               Swal.fire("Success!", "Your order has been placed.", "success");
               navigate("/");
            }
         });
      }
   }

   return (
      <>
         <div className="container">
            <div
               className={
                  !props.updateDarkMode
                     ? "mt-2 mb-md-5 text-black-50"
                     : "mt-2 mb-md-5 text-white-50"
               }
            >
               Product /
               <Link
                  to={`/search/${product.gatag}`}
                  className=" text-decoration-none"
               >
                  {product.gatag}{" "}
               </Link>{" "}
               /{" "}
               <Link
                  to={`/search/${product.brand}`}
                  className=" text-decoration-none"
               >
                  {product.brand}{" "}
               </Link>{" "}
               /
               <span
                  className={
                     !props.updateDarkMode ? "text-black" : "text-white"
                  }
               >
                  {product.title}
               </span>{" "}
            </div>
            <div className="row row-cols-1 row-cols-md-2 p-5  ">
               <div className="col">
                  <div className=" mb-4 cardono ">
                     <img
                        src={image}
                        className="card-img img-fluid p-lg-3"
                        alt={product.title}
                        title={product.title}
                     />
                  </div>
               </div>

               <div className="col mb-4 pe-md-5 ps-md-5 ">
                  <h4 className="card-title mb-2 ">{product.title}</h4>
                  <div className=" d-flex mt-1 mb-2 ">{stars}</div>
                  <div>
                     <a>{product.gatag} </a>
                  </div>
                  <h6
                     className={
                        !props.updateDarkMode
                           ? "mt-2 mb-4 text-black-50 "
                           : "mt-2 mb-4 text-white-50 "
                     }
                  >
                     {`$${product.shipping} Import Fees Deposit to Algeria`}{" "}
                  </h6>
                  <h5 className="card-title">${product.price}</h5>

                  {/* buttons for add to cart and buy now */}
                  {!isIncart ? (
                     <a
                        className="btn btn-primary btn-lg w-50 mt-4 fs-6 "
                        onClick={handleAddTocart}
                     >
                        Add to cart
                     </a>
                  ) : (
                     <a
                        className="btn btn-primary btn-lg w-50 mt-4 fs-6 "
                        onClick={handleRemoveFromcart}
                     >
                        Remove From cart
                     </a>
                  )}
                  <br />
                  <button
                     className="btn btn-outline-primary btn-lg w-50 mt-4 fs-6 mb-3 "
                     onClick={buymnow}
                  >
                     Buy now
                  </button>

                  <div className=" ">
                     <h5 className="card-title">Description:</h5>
                     <ReadMore>{product.description}</ReadMore>
                  </div>

                  <div className="prop-prd row mt-2 me-md-5 ">
                     <div className="col-6 mt-5">
                        {product.brand && (
                           <h6 className="card-text">Brand: </h6>
                        )}
                        <h6 className="card-text">Model:</h6>
                        {product.color && (
                           <h6 className="card-text">Color: </h6>
                        )}
                        {product.size && <h6 className="card-text">Size: </h6>}
                        {product.weight && (
                           <h6 className="card-text">Weight: </h6>
                        )}
                        {product.dimensions && (
                           <h6 className="card-text">Dimensions: </h6>
                        )}
                        <br />
                     </div>
                     <div className="col-6 mt-5">
                        {product.brand}
                        <br />
                        {product.title}
                        <br />
                        {product.color}
                        <br />
                        {product.size}
                        <br />
                        {product.weight}
                        <br />
                        {product.dimensions}
                        <br />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
