import React from "react";
import HeroPage from "./heropage";
import MainProducts from "./mainproducts";

export default function Home(props) {


   return (
      <>
         <HeroPage updateDarkMode={props.updateDarkMode} />
         <MainProducts
            products={props.products}
            toggleFav={props.toggleFav}
            isfav={props.isfav}
         />
      </>
   );
}
