import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PlaceholderImage from "../pics/moud.png";

export default function CardProduct(props) {
   const wishlistString = localStorage.getItem("wishlist") || "[]"; // default value is an empty array
   const msfavv = wishlistString.includes(props.id);
   const [isInWishlist, setIsInWishlist] = React.useState(msfavv);

   //cart

   const cartString = localStorage.getItem("cart") || "[]"; // default value is an empty array
   const mscart = cartString.includes(props.id);
   const [isIncart, setIsIncart] = React.useState(mscart);

   function handleAddTocart() {
      if (!token) {
         Swal.fire({
            title: "You are not logged in !",
            text: "for add to wishlist you need to login first",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Log in",
         }).then((result) => {
            if (result.isConfirmed) {
               localStorage.clear();
               navigate("/login");
            }
         });
         return;
      }

      fetch("https://smart-on-api.onrender.com/cart", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            productId: props.id,
            userId: localStorage.getItem("id"),
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            setIsIncart(true);
            localStorage.setItem("cart", data.cart);
         })
         .catch((err) => console.error(err));
   }

   function handleRemoveFromcart() {
      const token = localStorage.getItem("token");

      if (!token) {
         // handle the case where the user is not logged in
         return;
      }

      fetch(`https://smart-on-api.onrender.com/cart/${props.id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            productId: props.id,
            userId: localStorage.getItem("id"),
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            setIsIncart(false);
            localStorage.setItem("cart", data.cart);
            localStorage.removeItem(`quantity-${props.id}`);
         })

         .catch((err) => console.error(err));
   }

   const navigate = useNavigate();
   const token = localStorage.getItem("token");
   function handleAddToWishlist() {
      if (!token) {
         Swal.fire({
            title: "You are not logged in !",
            text: "for add to wishlist you need to login first",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Log in",
         }).then((result) => {
            if (result.isConfirmed) {
               localStorage.clear();
               navigate("/login");
            }
         });
         return;
      }

      fetch("https://smart-on-api.onrender.com/wishlist", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            productId: props.id,
            userId: localStorage.getItem("id"),
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            setIsInWishlist(true);
            localStorage.setItem("wishlist", data.wishlist);
         })
         .catch((err) => console.error(err));
   }

   function handleRemoveFromWishlist() {
      const token = localStorage.getItem("token");

      if (!token) {
         return;
      }

      fetch(`https://smart-on-api.onrender.com/wishlist/${props.id}`, {
         method: "DELETE",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({
            productId: props.id,
            userId: localStorage.getItem("id"),
         }),
      })
         .then((res) => res.json())
         .then((data) => {
            setIsInWishlist(false);
            localStorage.setItem("wishlist", data.wishlist);
         })

         .catch((err) => console.error(err));
   }

   let image = `..${props.image} `;

   let nbrstars = props.stars;
   let stars = [];
   for (let i = 0; i < nbrstars; i++) {
      stars.push(<i className="fa-solid fa-star star-ico " key={i}></i>);
   }

   return (
      <>
         <div className=" product-cart-mini">
            <div className="cardi ">
               <Link to={`/product/${props.id}  `}>
                  <LazyLoadImage 
                     src={image}
                     className="card-img product-img img-fluid"
                     alt={props.title}
                     title={props.title}
                     placeholderSrc={PlaceholderImage}
                     effect="blur"
                  />
               </Link>

               <button
                  className="heart-ico"
                  onClick={
                     isInWishlist
                        ? handleRemoveFromWishlist
                        : handleAddToWishlist
                  }
               >
                  {isInWishlist ? (
                     <i className="fa-solid fa-heart "></i>
                  ) : (
                     <i className="fa-regular fa-heart "></i>
                  )}
               </button>
            </div>
            <div className=" d-flex justify-content-between cord">
               <h5 className="card-title">{props.title}</h5>
               <h5 className="card-title">${props.price} </h5>
            </div>
            <div className=" gatag d-flex ">
               <Link
                  className="card-text  text-decoration-none"
                  to={`/category/${props.gatag}`}
               >
                  {props.gatag}
               </Link>
            </div>{" "}
            <div className=" d-flex mt-1 ">{stars}</div>
            {!isIncart ? (
               <button
                  className=" cart-btn mt-3 mb-5"
                  onClick={handleAddTocart}
               >
                  Add to cart
               </button>
            ) : (
               <button
                  className=" cart-btn mt-3 mb-5"
                  onClick={handleRemoveFromcart}
               >
                  Remove from cart
               </button>
            )}
         </div>
      </>
   );
}
