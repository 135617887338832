import React from "react";
import CardProduct from "../component/cardproduct";
import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide.min.css";
import adpc from "../pics/ad-pc.png";
// import adsmartphone from "../pics/ad-phone.png";
import adsmartwatch from "../pics/ad-sw.png";
import adkeyboard from "../pics/ad-key.png";
import LazyLoad from "react-lazyload";

export default function MainProducts(props) {
   let Products = props.products;
   let Prods = props.products.slice();

   const perPage = React.useMemo(() => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 992) {
         return 4; // 4 items per page for large screens
      } else if (screenWidth >= 768) {
         return 3; // 3 items per page for medium screens
      } else {
         return 2; // 2 items per page for small screens
      }
   }, []);

   function SplideSlider() {
      React.useEffect(() => {
         new Splide("#splide", {
            perMove: 1,
            speed: 1000,
            padding: {
               right: "1rem",
               left: "1rem",
            },
            loop: true,
            gap: "1rem",
            pauseOnHover: true,
            pagination: false,
            // Set perPage based on screen size
            perPage: perPage,
         }).mount();

         new Splide("#splide2", {
            perMove: 1,
            speed: 1000,
            padding: {
               right: "1rem",
               left: "1rem",
            },
            gap: "1rem",
            pauseOnHover: true,
            pagination: false,
            // Set perPage based on screen size
            perPage: perPage,
         }).mount();

         new Splide("#splide3", {
            perMove: 1,
            speed: 1000,
            padding: {
               right: "1rem",
               left: "1rem",
            },
            gap: "1rem",
            pauseOnHover: true,
            pagination: false,
            // Set perPage based on screen size
            perPage: perPage,
         }).mount();

         new Splide("#splide4", {
            perMove: 1,
            speed: 1000,
            padding: {
               right: "1rem",
               left: "1rem",
            },
            gap: "1rem",
            pauseOnHover: true,
            pagination: false,
            // Set perPage based on screen size
            perPage: perPage,
         }).mount();

         new Splide("#splide5", {
            perMove: 1,
            speed: 1000,
            padding: {
               right: "1rem",
               left: "1rem",
            },
            gap: "1rem",
            pauseOnHover: true,
            pagination: false,
            // Set perPage based on screen size
            perPage: perPage,
         }).mount();
      }, []);
      return null;
   }

   // Usage
   return (
      <div>
         <h4 className=" ms-4 mb-4">Featured Products</h4>
         <SplideSlider />
         <div className="splide" id="splide">
            <div className="splide__track">
               <div className=" container mt-5 splide__list ">
                  {Products.map((item) => {
                     return (
                        <div
                           className="row row-cols-md-3 row-cols-lg-4  row-cols-2 splide__slide"
                           key={item._id}
                        >
                           <div className=" w-100 h-100">
                              <LazyLoad height={200}>
                                 <CardProduct
                                    id={item._id}
                                    title={item.title}
                                    price={item.price}
                                    gatag={item.gatag}
                                    image={item.image}
                                    stars={item.stars}
                                    shipping={item.shipping}
                                    isfav={item.wishlist}
                                 />
                              </LazyLoad>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>

         <h4 className=" mt-5 ms-4 mb-4">Discover the Cheapest Products</h4>
         <SplideSlider />
         <div className="splide" id="splide2">
            <div className="splide__track">
               <div className=" container mt-5 splide__list ">
                  {Prods.sort((a, b) => a.price - b.price).map((item) => {
                     return (
                        <div
                           className="row row-cols-md-3 row-cols-lg-4  row-cols-2 splide__slide"
                           key={item._id}
                        >
                           <div className=" w-100 h-100">
                              <LazyLoad height={200}>
                                 <CardProduct
                                    key={item._id}
                                    id={item._id}
                                    title={item.title}
                                    price={item.price}
                                    gatag={item.gatag}
                                    image={item.image}
                                    stars={item.stars}
                                    shipping={item.shipping}
                                    isfav={item.wishlist}
                                 />
                              </LazyLoad>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>

         <div className="ad-img mt-5">
            <img className=" img-fluid w-100" src={adpc} alt="ad" />
         </div>
         <h4 className=" mt-5 ms-4 mb-4">Build your PC</h4>
         <SplideSlider />
         <div className="splide" id="splide3">
            <div className="splide__track">
               <div className=" container mt-5 splide__list ">
                  {Products.filter((item) => item.gatag === "PC").map(
                     (item) => {
                        return (
                           <div
                              className="row row-cols-md-3 row-cols-lg-4  row-cols-2 splide__slide"
                              key={item._id}
                           >
                              <div className=" w-100 h-100">
                                 <LazyLoad height={200}>
                                    <CardProduct
                                       key={item._id}
                                       id={item._id}
                                       title={item.title}
                                       price={item.price}
                                       gatag={item.gatag}
                                       image={item.image}
                                       stars={item.stars}
                                       shipping={item.shipping}
                                       isfav={item.wishlist}
                                    />
                                 </LazyLoad>
                              </div>
                           </div>
                        );
                     }
                  )}
               </div>
            </div>
         </div>
         <LazyLoad height={200}>
            <div className="ad-img mt-5 d-flex align-items-center">
               <img className=" img-fluid w-100" src={adkeyboard} alt="ad" />
            </div>{" "}
         </LazyLoad>
         <h4 className=" mt-5 ms-4 mb-4">Exclusive Keyboards and Mouses</h4>
         <SplideSlider />
         <div className="splide" id="splide4">
            <div className="splide__track">
               <div className=" container mt-5 splide__list ">
                  {Products.filter(
                     (item) =>
                        item.gatag === "Keyboard" || item.gatag === "Mouse"
                  ).map((item) => {
                     return (
                        <div
                           className="row row-cols-md-3 row-cols-lg-4  row-cols-2 splide__slide"
                           key={item._id}
                        >
                           <div className=" w-100 h-100">
                              <LazyLoad height={300}>
                                 <CardProduct
                                    key={item._id}
                                    id={item._id}
                                    title={item.title}
                                    price={item.price}
                                    gatag={item.gatag}
                                    image={item.image}
                                    stars={item.stars}
                                    shipping={item.shipping}
                                    isfav={item.wishlist}
                                 />
                              </LazyLoad>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
         <LazyLoad height={200}>
            <div className="ad-img mt-5 d-flex align-items-center">
               <img className=" img-fluid w-100" src={adsmartwatch} alt="ad" />
            </div>
         </LazyLoad>
         <h4 className=" mt-5 ms-4 mb-4">Exclusive Smartwatch</h4>
         <SplideSlider />
         <div className="splide" id="splide5">
            <div className="splide__track">
               <div className=" container mt-5 splide__list ">
                  {Products.filter((item) => item.gatag === "Smartwatch").map(
                     (item) => {
                        return (
                           <div
                              className="row row-cols-md-3 row-cols-lg-4  row-cols-2 splide__slide"
                              key={item._id}
                           >
                              <div className=" w-100 h-100">
                                 <LazyLoad height={300}>
                                    <CardProduct
                                       key={item._id}
                                       id={item._id}
                                       title={item.title}
                                       price={item.price}
                                       gatag={item.gatag}
                                       image={item.image}
                                       stars={item.stars}
                                       shipping={item.shipping}
                                       isfav={item.wishlist}
                                    />
                                 </LazyLoad>
                              </div>
                           </div>
                        );
                     }
                  )}
               </div>
            </div>
         </div>
      </div>
   );
}
